var LENGTH = window.location.href.split("/").length - 1

// Smooth scroll
if (window.location.href.split("/")[LENGTH] !== "privacy" ) {
  $("a").on('click', function(event) {
    links = document.getElementsByClassName('nav-link');
    var i;
    for (i = 0; i < links.length; i++) {
      links[i].classList.remove('active');
    }
    event.target.classList.add("active");
    if (this.hash !== "") {
      event.preventDefault();
      var hash = this.hash;
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){
        window.location.hash = hash;
      });
    }
  });

  // when any nav_item is clicked, collapseNavbar
  var nav_items = document.getElementsByClassName("nav-item");
  for (var i = 0; i < nav_items.length; i++) {
    nav_items[i].addEventListener('click', collapseNavbar, false);
  }
  // Click hamburger to close navigation
  function collapseNavbar() {
    if ($(window).width() < 768) {
      setTimeout(function(){
        document.getElementById('hamburger').click();
      }, 600);
    };
  };
}

var hamburger = document.getElementById('hamburger');
if (hamburger !== 'undefined' && hamburger !== null) {
  hamburger.addEventListener('click', function() {
    hamburger.classList.toggle('change');
    if (document.getElementById('drytexNavbar').classList.contains('show')) {
      document.getElementById('drytexNavbar').classList.add('show');
    } else {
      document.getElementById('drytexNavbar').classList.toggle('hide');
    }
  });
}

document.addEventListener("click", function(event) {
  if (event.target.id == "close_success_message") {
    document.getElementById('overlay').style.display = 'none';
  }
})
  
var overlay = document.getElementById('overlay');
if (overlay !== 'undefined' && overlay !== null) {
  overlay.addEventListener('click', function () {
    document.getElementById('overlay').style.display = 'none';
  });
}

$(document).on("scroll", function onScroll(event) {
  if (window.location.href.split("/")[LENGTH] !== "privacy" ) {
    var scrollPosition = $(document).scrollTop() + 100;
    $('.nav-item a').each(function() {
      var currentLink = $(this);
      var hash = currentLink.attr("href")
      var refElement = $(hash.slice(1));;
      if (refElement.position().top < scrollPosition && refElement.position().top + refElement.height() > scrollPosition) {
        $('nav ul li a').removeClass("active");
        currentLink.addClass("active");
      } else {
        currentLink.removeClass("active");
      }
    });
  }
});
